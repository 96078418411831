import { createApp } from 'vue'
import { createPinia } from 'pinia'
import type { App } from 'vue'
import 'vue-final-modal/style.css'
import { vfm } from './plugins/vue-final-modal';
import 'vue3-easy-data-table/dist/style.css';
import { date } from './composables/date.filter'
//import App from './App.vue'
//
const pinia = createPinia()

type WidgetGetterFunction<T> = (...args: any[]) => Promise<T>
type WidgetEventFunction = (data: any) => void | Promise<void>
type WidgetEvents = {
  suscribe: (ev: string, fn: WidgetEventFunction) => void
  unsuscribe: (ev: string, fn: WidgetEventFunction) => void
}

declare global {
  interface Window {
    vue: {
      openView(view: string, hash: string, rootScope: any, resourceHelperMethods: resourceHelper, factoryBus: any): Promise<App<Element>>,
      openWidget<Data, Config>(widget: string, selector: string, getConfig: WidgetGetterFunction<Config>, getData: WidgetGetterFunction<Data>, events: WidgetEvents): Promise<App>,
      app?: App,
    }
    i18next: { t: Function }
    profile: any,
    sharedCfg: any
  }
}

type resourceHelper = {
  uploadTask: () => Promise<any>
  humanFileSize: () => string,
  getFileUrl: (object: any, filename: string, crc: string, apiUrl: string, updatedFiles: any, sharedCfg: any) => string
}

// Declarar acá los tipos de los filtros creados
interface filters {
  date: typeof date
}

// Acá se exitenden los tipos que espera Vue en el contenido de los componentes. Esto se hace para evitar errores al usar filtros en las templates
declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $filters: filters,
    $t: (string: string, arguments?: any[]) => string
  }
}

window.vue = {
  openView,
  openWidget
}

//
async function openView(view: string, selector: string, rootScope: any, resourceHelperMethods: resourceHelper, factoryBus: any): Promise<App<Element>> {
  let module = await import(`./views/${view}.vue`);
  let app = createApp(module.default)
  window.vue.app = app;
  // Se agrega traducción por i18next
  app.provide('ngRootScope', rootScope) // Hago disponible el rootScope de Angular por si es necesario. Doc de Provide/Inject -> https://vuejs.org/guide/components/provide-inject.html
  app.provide('factoryBus', factoryBus)
  app.config.globalProperties.$t = function () { return window.i18next.t.apply(this, arguments) }
  app.config.globalProperties.$filters = {
    date
  }
  app.config.globalProperties.resourceHelperMethods = resourceHelperMethods
  app.config.globalProperties.ngRootScope = rootScope
  app.use(vfm)
    .use(pinia)
    .mount(selector)
  return app;
}

/**
 * @param widget Nombre del widget en PascalCase
 * @param selector Selector css del elemento sobre el que se quiere montar el widget
 * @param getConfig Una función que ejecuta el widget para obtener la configuración
 * @param getData Una función que ejecuta el widget para obtener los datos
 * @param events Eventos y sus reacciones
 * @param interactions Interacciones del widget que se ejecutan en angular
 * @param widgetOrigin String que determina el origen del widget
 */

async function openWidget<Data, Config>(widget: string, selector: string, getConfig: WidgetGetterFunction<Config>, getData: WidgetGetterFunction<Data>, widgetEvents?: WidgetEvents, interactions?: any, fontSize?: string, widgetOrigin?: string): Promise<App> {
  const widgetComponent = await import(`@widgets/${widget.toLowerCase()}/${widget}.vue`)
  const app = createApp(widgetComponent.default, { getConfig, getData, widgetEvents, interactions, widgetOrigin })
  // app.provide('widgetTools', {getConfig, getData, EventBus})
  app.mount(selector)
  return app
}